import { NotificationThreshold, NotificationThresholdType } from 'interfaces/notificationItem';

export const getNotificationThreshold = (input: any, type: NotificationThresholdType) => {
  if (!input) {
    return '';
  }

  if (type === NotificationThreshold.High) {
    return input.threshold_upper || input.threshold_high;
  } else {
    return input.threshold_lower || input.threshold_low;
  }
};

export const getNotificationCorrection = (
  input: any,
  factor_list: { value: string; displayValue: string }[]
) => {
  const defaultCorrection = factor_list.find((item) => item.value === '0');
  if (!input) {
    return defaultCorrection;
  }

  if (input.correction_factor !== null && input.correction_factor !== undefined) {
    let initialCorrectionDisplay = factor_list.find(
      (item) => item.value.toString() == input.correction_factor.toString()
    );
    console.log('initialCorrectionDisplay', initialCorrectionDisplay);
    return initialCorrectionDisplay || defaultCorrection;
  }
  return defaultCorrection;
};
